.selection-box {
  max-width: 320px;
  padding: 30px;
  margin: 15px;

  .selection-box-expanding-content-wrapper {

    .selection-box-expanding-content {
      flex-grow: 1;

      &.has-requirements {
        flex-grow: 0;
      }
    }

    .selection-box-requirements-section {
      &.has-requirements {
        flex-grow: 1;
      }
    }
  }  
}
