.fade-and-slide-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.fade-and-slide-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.fade-and-slide-left-enter-active {
  transform: translateX(0%);
  opacity: 1;
}

.fade-and-slide-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
}

.fade-and-slide-left-enter-active,
.fade-and-slide-left-exit-active{
  transition: opacity 500ms, transform 500ms;
}

.fade-and-slide-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-and-slide-right-exit {
  transform: translateX(0%);
  opacity: 1;
}

.fade-and-slide-right-enter-active {
  transform: translateX(0%);
  opacity: 1;
}

.fade-and-slide-right-exit-active {
  transform: translateX(-100%);
  opacity: 0;
}

.fade-and-slide-right-enter-active,
.fade-and-slide-right-exit-active{
  transition: opacity 500ms, transform 500ms;
}

.wizard-max-inner {
  margin: 0 auto;
  margin-top: 20px;
  max-width: 500px;

  &.full-size {
    max-width: 710px;
  }
}

.onboarding-screen-background {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  // height: 1024px;
  background-image: url('/assets/pexels-photo-1181717-e1540844592690.jpeg');
  background-size: cover;
  background-position: 50% 0;
  overflow-y: hidden;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: white;
  }
}
