.progress-component {
  // max-width: 300px;
}

.progress-component-container {
  max-width: 300px;
  text-align: center;
  position: relative;
  font-weight: bold;

  &::before {
    content: attr(data-text);
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    line-height:1.3em;
  }
}

