.donate-box-wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  //width: 70%;

  .donate-box {
    width: 30rem;
    border-radius: 0;
    height: 220px;
    z-index: 100;
  
    .donate-button {
      width: 100%;
      text-transform: uppercase;
      font-weight: bold;
    
    }
  }
}

.login-background {
  width: 100%; 
  height: 100vh; 
  position: fixed;
  //background: black;
  left: 0;
  top: 0;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-image: url('/assets/iStock-638953230_n3clzp.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    z-index: 0;
  }
}

.login-logo {
  width: 400px;
  margin-top: 80px;
  margin-bottom: 40px;
  object-fit: 'cover';
  z-index: 5;
}

.donate-logo {
  text-align: center;
  width: 300px;
  margin-top: 60px;
  // margin-bottom: 40px;
  // object-fit: 'cover';
  z-index: 5;
}

.onboarding-background {
  width: 100%; 
  height: 100vh; 
  position: fixed;
  background: black;
  left: 0;
  top: 0;

  &:before {
    content: ' ';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-image: url('/assets/pexels-photo-1181717-e1540844592690.jpeg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    z-index: 0;
  }
}
