.todoitem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  margin-bottom: 20px;

  .todoitem-icon {
    padding-right: 10px;
    // background-color: red;
    font-size: 24px;
  }

  .todoitem-content {
    padding-left: 10px;
  }

  &:hover {
    transition: color .5s;

    // >* {
    //   >* {
    //     transition-timing-function: ease-in;
    //     transition: color 2s;
    //     color: blue;
    //   }
    // }
  }

  .orange {
    color: orange;
  }
}