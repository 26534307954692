@import 'bulma/sass/utilities/derived-variables.sass';

// Make default link colours $info color
$link: $info;

@import 'bulma/bulma.sass';
@import 'bulma-extensions/bulma-switch/src/sass/index.sass';
@import 'bulma-extensions/bulma-checkradio/src/sass/index.sass';

.side-by-side-bulma {
  @extend .mr-4;
}

.modal-background {
  background-color: rgba(30, 30, 30, 0.66);
}

a {
  &:hover {
    color: #3273dc;
  }
}

table {
  &.is-hoverable {
    tr {
      &:hover {
        background-color: hsl(207, 61%, 53%, 20%) !important;
      }
    }
  }
}