@media (max-width: 600px) {
  body {
    // background-color: red;
    font-size: 16px;
  }

  .login-logo {
    width: 300px !important;
  }

  
  .logo-banner-wrapper {
    font-size: smaller;
  }

  .dashboard {
    padding-top: 20px !important;
  }

  .card-content {
    padding: 0.5rem !important;
  }

  h1 {
    &.title {
      font-size: 1.6rem;
    }

    &.subtitle {
      font-size: 1.1rem;
    }
  }

  hr {
    margin: 1.2rem 0;
  }

  ol {
    &.upcoming-events {
      li {
        padding-bottom: 12px;
        flex-wrap: wrap;

        .buttons {
          padding-top: .75rem;
        }
      }
    }
  }

  .block {
    margin-bottom: 1rem !important;
  }

  .modal-card {
    padding: 0 10px !important;
  }
 
  .profile-info-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }

  #topNavBar {
    .navbar-item {
      color: initial;
    }
  }

  .profile-page {
    hr {
      margin-top: 0rem;
    }
  }

  .questionnaire {
    margin-top: 35px;
    .questionnaire-progress-bar-wrapper {
      width: 250px;
    }

    .card {
      border: none;
      border-radius: 0;

      .card-footer-item {
        border: none;
      }
    }

    .likertBand {
      font-size: x-small;
      width: 100%;
    }
  }

}