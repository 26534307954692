.hero {
  &.has-background {
    position: relative;
    overflow: hidden;
  }

  &-background {
    position: absolute;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
  
    &.is-transparent {
      opacity: 0.3;
    }
  }
}

.top-image-card {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  height: 288px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  padding: 0;
  margin: 0;
}

.universityColorGradient {
  background: rgb(47,16,119);
  background: linear-gradient(90deg, rgba(47,16,119,1) 0%, rgba(247,200,47,1) 100%);
  height: 20px;
}

.dashboard {
  padding-top: 3.25rem;

  .tern-list-item-title {
    font-size: 20px;
    font-weight: bold;
    font-family: inherit;
  }

  ul {
    &.tern-list {
      list-style-type: none;
      line-height: normal;
      margin-left: 0;
    }

    .tern-list-item-title {
      font-size: 20px;
      font-weight: bold;
      font-family: inherit;
    }

    li {
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}