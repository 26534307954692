.App {
  text-align: center;
}

//Tern color: #e03a3c

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

ol {
  &.upcoming-events {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.questionnaire {
  margin-top: 90px;
  .questionnaire-progress-bar-wrapper {
    width: 300px;
    margin: auto;
  }
}

.profile-info-wrapper {
  float: right;
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// TODO - MOVE ALL THIS
.navbar {
  background-color: #e03a3c;
}

.navbar-item {
  color: white;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: white;
}

.navbar-item.has-dropdown .navbar-link {
  color: #e03a3c;
}

.navbar-dropdown .navbar-item {
  color: #e03a3c;
}

.navbar-item.has-dropdown:hover .navbar-link {
  background-color: #e03a3c;
}

.navbar-dropdown a.navbar-item:hover {
  background-color: #e03a3c;
  color: white;
}

.optionListContainer {
  z-index: 200 !important;
}

.explanationText {
  font-style: italic;
  font-size: small;
}

.react-datepicker-popper {
  z-index: 99 !important;
}

.info-item {
  margin-bottom: 1.3rem;
  label {
    margin-bottom: 0 !important;
    // display: inline-block;
    // width: 8rem;
  }
}

.social-media-item {
  margin-bottom: .5rem;
  span {
    margin-left: 12px;
  }
}