
.surveystars {
  float: left;
  height: 46px;
  padding: 0 10px;
}
.surveystars:not(:checked) > input {
  position:absolute;
  top:-9999px;
}
.surveystars:not(:checked) > label {
  float:right;
  width:1em;
  overflow:hidden;
  white-space:nowrap;
  font-size:30px;
  color:#ccc;
}
.surveystars:not(:checked):not(.disabled) > label {
  cursor:pointer;
}
.surveystars:not(:checked) > label:before {
  content: '★ ';
}
.surveystars > input:checked ~ label {
  color: #ffc700;    
}
.surveystars:not(:checked):not(.disabled) > label:hover,
.surveystars:not(:checked):not(.disabled) > label:hover ~ label {
  color: #deb217;  
}
.surveystars:not(.disabled) > input:checked + label:hover,
.surveystars:not(.disabled) > input:checked + label:hover ~ label,
.surveystars:not(.disabled) > input:checked ~ label:hover,
.surveystars:not(.disabled) > input:checked ~ label:hover ~ label,
.surveystars:not(.disabled) > label:hover ~ input:checked ~ label {
  color: #c59b08;
}
